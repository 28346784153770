import React, {useState} from 'react'
import './Tabs.scss'

export default function Tabs({titleOne, titleTwo, tabFillOne, tabFillTwo}) {

  const [oneSelected, setOneSelected] = useState(true)
  const oneIsSelected = oneSelected ? "selected" : "not-selected";
  const twoIsSelected = oneSelected ? "not-selected" : "selected";
  const show = oneSelected ? tabFillOne : tabFillTwo;
  return (
    <div className="tabs-container">
      <div className="tab-nav">

        <div className="tab" 
           role="button" 
           tabIndex={0} 
           onClick={() => setOneSelected(true)}
           onKeyDown={() => setOneSelected(true)}
        >
          <div className="tab-clickable-area">{titleOne}</div>
          <div className={oneIsSelected}></div>
        </div>

        <div className="tab" 
          role="button" 
          tabIndex={0} 
          onClick={() => setOneSelected(false)}
          onKeyDown={() => setOneSelected(false)}
        >
          <div className="tab-clickable-area" >{titleTwo}</div>
          <div className={twoIsSelected}></div>
        </div>

      </div>
      <div className="selected-tab">
        {show}
      </div>
    </div>
  )
}