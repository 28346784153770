import React from "react"
import SectionHeader from '../../components/SectionHeader'
import Tabs from '../../components/Tabs'

export default function BodiesWithVulva() {
  return (
    <div className="bodies-with-vulva-container">
      <SectionHeader title="Bodies with vulva">
      </SectionHeader>
      <Tabs
        titleOne="INTERNAL"
        titleTwo="EXTERNAL"
        tabFillOne={<div > A </div>}
        tabFillTwo={<div > B </div>}
        ></Tabs>
    </div>
  )
}